<template>
  <div class="">
    <b-skeleton-wrapper :loading="loading">
      <p class="m-list-title">Ultimi contatti inseriti</p>
      <b-list-group>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
      </b-list-group>
      <b-skeleton type="button" class="mt-3" width="35%"></b-skeleton>
    </b-skeleton-wrapper>
    <section v-if="loading == true">
      <b-list-group>
        <p class="m-list-title">Ultimi contatti inseriti</p>

        <b-list-group-item
          class="d-flex justify-content align-items-center m-listmin"
          v-for="date in payload.contacts"
          v-bind:key="date.id"
          :to="{ name: 'DettagliContatto', query: { id: date.id } }"
          >{{ chageFormatDate(date.createdDt) }} - {{ date.person.firstName }}
          {{ date.person.lastName }}
          
        </b-list-group-item>
        <b-list-group-item v-if="payload.contacts === []" disabled
          >Nessun contatto
        </b-list-group-item>
      </b-list-group>
      <b-button :to="{ name: 'Contatti' }" class="mt-4" size=""
        >Vedi tutti i contatti</b-button
      >
    </section>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "AppLastActivityInsert",
  data() {
    return {
      loading: false,
      showLessResult: true,
      payload: [],
    };
  },

  created() {
    this.listContact();

  },

  methods: {
    ...mapActions(["contact/getLastContactInsert"]),
  
    async listContact() {
      try {
        console.log("contact/getLastContactInsert");
        await this.$store.dispatch("contact/getLastContactInsert").then(() => {
          this.payload = this.$store.getters["contact/lastContactInsert"];
          this.loading = true;
          return this.payload;
        });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error lastContact" });
      }

      try {
        await this.$store.dispatch("activity/getActivity").then(() => {
          return;
        });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },
    LastActivityNameById($array) {
      let activitiesArray = $array;
      const data = [];
      activitiesArray.forEach((element) => {
        data.push({
          idActivity: element.idActivity,
          eventDt: new Date(element.eventDt).getTime(),
        });
      });

      function compare(a, b) {
        if (
          a == undefined ||
          b == undefined ||
          a.eventDt == undefined ||
          b.eventDt == undefined
        ) {
          return 0;
        }
        if (new Date(b.eventDt).getTime() < new Date(a.eventDt).getTime()) {
          return -1;
        }
        if (new Date(a.eventDt).getTime() > new Date(b.eventDt).getTime()) {
          return 1;
        }
        return 0;
      }

      const sortActivities = data.sort(compare);
      /*  console.log(Activities); */

      const activitiesId = sortActivities[0].idActivity;
      const activityName = this.$store.getters["activity/activityById"](
        activitiesId
      );
      /*       console.log("LastActivityNameById"); */
      let name = 0;
      if (activityName !== undefined) {
        name = activityName.activity.name;
      } else {
        name = 0;
      }
      return name;
    },
 
    chageFormatDate($date) {
      var gmtDateTime = moment.utc(new Date($date), "YYYY-MM-DD HH:mm:ss");
      var local = gmtDateTime.local().format("DD/MM/YYYY HH:mm");
      return local;
    },
  },
};
</script>
