import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
/* import axios from 'axios' */
import store from '@/store/index.js';
import Dashboard from "../views/Dashboard";


/* import TipologiaAttivita from "../views/TipologiaAttivita"; */
/* import DettagliAttivita from "../views/DettagliAttivita"; */
/* import Attivita from "../views/Attivita"; */
/* import Contatti from "../views/Contatti"; */
/* import Importazioni from "../views/Importazioni"; */

Vue.use(VueRouter)

const routes = [


  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,

    meta: {
      auth: false,
      title: 'CRM - Dashboard'
    }

  },
  {
    path: '/dettagli-attivita',
    name: 'DettagliAttivita',
    component: () => import('../views/DettagliAttivita.vue'),

    meta: {
      auth: false,
      title: 'CRM - Dettagli Attività'
    }

  },
  {
    path: '/tipologia-attivita',
    name: 'TipologiaAttivita',
    component: () => import('../views/TipologiaAttivita.vue'),

    meta: {
      auth: false,
      title: 'CRM - Tipologia Attività'
    }
  },
  {
    path: '/attivita',
    name: 'Attivita',
    component: () => import('../views/Attivita.vue'),

    meta: {
      auth: false,
      title: 'CRM - Attività'
    }
  },
  {
    path: '/contatti',
    name: 'Contatti',
    component: () => import('../views/Contatti.vue'),

    meta: {
      auth: false,
      title: 'CRM - Contatti'
    }
  },
  {
    path: '/dettaglicontatto',
    name: 'DettagliContatto',
    component: () => import('../views/DettagliContatto.vue'),

    meta: {
      auth: false,
      title: 'CRM - Dettagli Contatto'
    }
  },
  {
    path: '/Importazioni',
    name: 'Importazioni',
    component: () => import('../views/Importazioni.vue'),

    meta: {
      auth: false,
      title: 'CRM - Importazioni'
    }
  },
  {
    path: '/DettagliImportazione',
    name: 'DettagliImportazione',
    component: () => import('../views/DettagliImportazione.vue'),
    meta: {
      auth: false,
      title: 'CRM - Dettagli Importazione'
    }

  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: true,
      title: 'CRM - Login'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      auth: true,
      title: 'CRM - Login'
    }
  },


]



const router = new VueRouter({
  mode: 'history',
  /*  base: process.env.BASE_URL, */
  routes,
  //linkActiveClass: "active",
  linkExactActiveClass: "active"
})

let date = new Date();
date.setHours(0, 0, 0, 0);

router.beforeEach((to, from, next) => {

  //verifico se utente è loggato, altrimenti dirotto alla pagina di login
  const logged = localStorage.getItem('logged');

  if (!store.getters.logged && `${logged}` !== `${date}` && to.name != 'Login') {
    store.dispatch('logout', false);
    localStorage.clear();
    next({ name: 'Login' });
  } else if (to.name == 'Logout') {
    store.dispatch('logout', false);
    next({ name: 'Login' });
    /* localStorage.removeItem('logged'); */
    localStorage.removeItem('refreshToken');
    localStorage.setItem('SET_LOGGED', false);
    localStorage.setItem('logged', false);
    /*  localStorage.clear(); */
    /*  store
      .dispatch.dispatch('logout', false)
      .then(() => {
        localStorage.clear();
        next({ name : 'Login' });
      }); */

  } else {
    console.log(store.getters["logged"])
    next();
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();

});

/* async logoutAction() {
  try {
    await store
      .dispatch.dispatch('logout', false)
      .then(() => {
        localStorage.clear();
        next({ name : 'Login' });
      });
  } catch (e) {
    console.log({ message: "Some error logout" });
  }
} */

export default router
