export default {
    SET_CONTACTS_IMPORTS(state, data) {
        state.ContactsImports = data.payload.imports;
        state.nrImport = data.payload.nrImports;
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(state.ContactsImports);
            console.log(state.nrImport);
        }
    },

    SET_CONTACTS_ID(state, data) {
        state.ContactsByIdPayload = data.payload.imports[0];
        state.ContactsById = data.payload.imports[0].importLines;
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(state.ContactsById);
        }
    },

    SET_ERROR_CONTACTS_IMPORTS(state, data) {
        state.errorCall.push(data);
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(state.errorCall);
        }
    }

    


};