export default {
    SET_KNOWNUNTYPES(state, data) {
        state.knownustypes = data.payload;
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(state.knownustypes);
        }
    },

    SET_ERROR_KNOWNUNTYPES(state, data) {
        state.errorCall.push(data);
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(state.errorCall);
        }
    }
};