export default {
  contact(state) {
    return state.contact;
  },

  singleContact(state) {
    return state.singleContact;
  },

  lastContact(state) {
    return state.lastContact;
  },

  lastContactInsert(state) {
    return state.lastContactInsert;
  },

  nrItem(state) {
    return state.nrContact;
  },

  perPage(state) {
    return state.perPage;
  },

  currentPage(state) {
    return state.currentPage;
  },

  hasContact(state) {
    return state.contact && state.contact.length > 0;
  },
  contactById: (state) => (id) => {
    return state.contact.find(contact => contact.id === id)
  },

  activitiesContactsById: (state) => (id) => {

    return state.activitiesContacts.find(activitiesContacts => activitiesContacts.id === id)
  }
};