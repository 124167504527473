<template>
  <div id="app">
    <app-skeleton></app-skeleton>
  </div>
</template>

<script>
// @ is an alias to /src
import AppSkeleton from "@/components/layout/AppSkeleton.vue";

export default {
  name: "App",
  components: {
    AppSkeleton,
  },
  meta: {
    auth: false,
    title: "CRM Gruppo Foppa HDEMIA - Login",
    //title: "CRM ITS - Login",
  },

  watch: {
    $route(to) {
      //document.title = to.meta.title || "CRM ITS";
      document.title = to.meta.title || "CRM Gruppo Foppa HDEMIA";
    },
  },
};
</script>
