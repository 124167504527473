import apiAxios from '../../../libs/apiAxios'
export default {
    async getCourses({ commit }) {
        await apiAxios.get('/api/foppacrm/courses')
        .then(response => {
            if(response.data.isSuccess == true){
                commit('SET_COURSES', response.data)
            } else {
                commit('SET_ERROR_COURSES', {
                    ...response.data,
                    call: "GET getCourses",
                    date: new Date()
                });
            }
        })
        .catch((error) => console.log(error));
    }, 


}; 