import apiAxios from '../../../libs/apiAxios'
export default {
    async getKnownustypes({ commit }) {
        await apiAxios.get('/api/foppacrm/knownustypes')
        .then(response => {
            if(response.data.isSuccess == true){
                console.log(response.data);
                commit('SET_KNOWNUNTYPES', response.data)
            } else {
                commit('SET_ERROR_KNOWNUNTYPES', {
                    ...response.data,
                    call: "GET getKnownustypes",
                    date: new Date()
                });
            }
        })
        .catch((error) => console.log(error));
    }, 


}; 