export default {
  listKnownusTypes(state) {
    console.log(state.knownustypes);
    return state.knownustypes;
  },

 /*  hasTypeActivity(state) {
    return state.typeActivity && state.typeActivity.length > 0;
  },*/

  typelistKnownById: (state) => (id) => {
    return state.knownustypes.find(knownustypes => knownustypes.id === id)
  }  
};