import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      contact: [],
      activitiesContacts: [],
      errorCall: [],
      nrContact: 0,
      perPage: 10,
      currentPage: 0,
      singleContact: [],
      lastContact: [],
      lastContactInsert: [],
    };
  },
  mutations,
  actions,
  getters
};
