<template>
  <div class="">
    <b-skeleton-wrapper :loading="loading">
      <p class="m-list-title">Ultimi attività assegnate contatti</p>
      <b-list-group>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
      </b-list-group>
      <b-skeleton type="button" class="mt-3" width="35%"></b-skeleton>
    </b-skeleton-wrapper>
    <section v-if="loading == true">
      <b-list-group>
        <p class="m-list-title">Ultimi attività assegnate contatti</p>

        <b-list-group-item
          class="d-flex justify-content align-items-center m-listmin"
          v-for="date in payload.contacts"
          v-bind:key="date.id"
          :to="{ name: 'DettagliContatto', query: { id: date.id } }"
          >{{ LastActivityNameById(date.contactsActivities).date }} - {{ date.person.firstName }}
          {{ date.person.lastName }}
          <span v-if="date.contactsActivities.length > 0" class=" ml-1">
            - {{ LastActivityNameById(date.contactsActivities).getnameActivity }}
          </span>
        </b-list-group-item>
        <b-list-group-item v-if="payload.contacts === []" disabled
          >Nessun contatto
        </b-list-group-item>
      </b-list-group>
      <b-button :to="{ name: 'Contatti' }" class="mt-4" size=""
        >Vedi tutti i contatti</b-button
      >
    </section>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "AppLastContacts",
  data() {
    return {
      loading: false,
      showLessResult: true,
      payload: [],
    };
  },

  created() {
    this.listContact();
    this.listActivity();
  },

  methods: {
    ...mapActions(["contact/getLastContact"]),
    async listActivity() {
      try {
        await this.$store.dispatch("activity/getActivity").then(() => {
          this.activity = this.$store.getters["activity/activity"];
          return this.activity;
        });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },
    async listContact() {
      try {
        console.log("contact/getLastContact");
        await this.$store.dispatch("contact/getLastContact").then(() => {
          this.payload = this.$store.getters["contact/lastContact"];
          this.loading = true;
          return this.payload;
        });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error lastContact" });
      }

      try {
        await this.$store.dispatch("activity/getActivity").then(() => {
          return;
        });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },
    LastActivityNameById($array) {
      let activitiesArray = $array;
      const data = [];
     /*  console.log('### RIORDIONO ARRAY');
      console.log(activitiesArray); */
      
      

     /*  function sortArrayOfObjects(arr, key) {
          return arr.sort((a, b) => {
            return a[key] - b[key];
          });
        }; */

      /* activitiesArray = sortArrayOfObjects(activitiesArray, "id"); */
    /*   const sortActivities = activitiesArray.sort((a,b)=> (a.id > b.id ? 1 : -1)) */
   
      activitiesArray.forEach((element) => {
      /*   var gmtDateTime = moment.utc(new Date(element.eventDt), "YYYY-MM-DD HH:mm:ss");
        var local = gmtDateTime.local().format("DD/MM/YYYY HH:mm");  */
        data.push({
          id : element.id,
          idActivity: element.idActivity,
          eventDt: new Date(element.eventDt).getTime(),
          dataFormat: moment(element.eventDt).subtract(2, 'hour').format("DD/MM/YYYY HH:mm")
        });
      });

    
      const sortActivities = data.sort((a,b)=> (a.id > b.id ? -1 : 1))
     
      const activitiesId = sortActivities[0].idActivity;
      const activityName = this.$store.getters["activity/activityById"](
        activitiesId
      );

      let name = 0;
      if (activityName !== undefined) {
        name = activityName.activity.name;
      } else {
        name = 0;
      }
      const dataArray = {
        getnameActivity : name,
        date : sortActivities[0].dataFormat

      }
     /*  console.log(new Date(sortActivities[0].dataFormat));
      console.log(dataArray); */
      return dataArray;
    },
    /*  LastActivityNameById($array) {
      const sortedActivities = $array;
      const activitiesId = sortedActivities[0].idActivity;
      const activityName = this.$store.getters["activity/activityById"](
        activitiesId
      );

      let name = 0;
      if (activityName !== undefined) {
        name = activityName.activity.name;
      } else {
        name = 0;
      }
      return name;
    }, */

    chageFormatDate($date) {
      var gmtDateTime = moment.utc(new Date($date), "YYYY-MM-DD HH:mm:ss");
      var local = gmtDateTime.local().format("DD/MM/YYYY HH:mm");
      return local;
    },
  },
};
</script>
