import apiAxios from '../../libs/apiAxios'
import axios from 'axios'


const state = {
  logged: false,
  /*token: null,
  refreshToken: null,*/
  userInfo: null
};

let date = new Date();
date.setHours(0, 0, 0, 0);
const mutations = {
  'SET_LOGGED'(state, payload) {
    console.log('### SET_log', payload);
    state.logged = payload.isSuccess;
    state.token = payload.authToken;
    state.refreshToken = payload.refreshToken;
    state.userInfo = payload;
    localStorage.setItem('token', payload.authToken);
    localStorage.setItem('refreshToken', payload.refreshToken);
    localStorage.setItem('logged', date);
    localStorage.setItem('SET_LOGGED', payload.authToken);
  },

  'SET_REFRESH'(state, payload) {
    state.logged = true;
    state.token = payload.authToken;
    state.refreshToken = payload.refreshToken;
    localStorage.setItem('token', payload.authToken);
    localStorage.setItem('refreshToken', payload.refreshToken);
    localStorage.setItem('logged', date);
    localStorage.setItem('SET_LOGGED', payload.isSuccess);
    console.log(state);
  }
};

const actions = {
  async login({ commit }, userObj) {
    //ajax call to login user

    const user = {
      "apikey": "1F2406A4-F30D-4E26-8E8D-6C8AE9E4230A",
      "username": userObj.username,
      "password": userObj.password,
      "joinWith": ["people_structure", "rights_structure"]
    }

    await axios.post(`${process.env.VUE_APP_API_ENDPOINT}api/uman/tokens/authenticate`,
      user)
      .then(response => {
        console.log(response);
        commit('SET_LOGGED', response.data.payload);
        return response;
      })
      .catch(error => {
        console.log('### ERROR: actions.login', error)

        return error;
      })
      ;
  },

  async refreshToken({ commit }) {

    const user = {
      "authToken": localStorage.getItem('token'),
      "refreshToken": localStorage.getItem('refreshToken'),
    }



    await axios.post(`${process.env.VUE_APP_API_ENDPOINT}api/uman/tokens/refresh`,
      user)
      .then(response => {
        console.log('refreshToken');
        /*   console.log(response); */


        if (response.data.isSuccess == true) {
          commit('SET_LOGGED', response.data.payload);
        }
      })
      .catch(error => {
        console.log('### ERROR: actions.refreshToken', error)
      })
      ;
  },

  async logout(/* {commit} */) {
    /*   let loginState = false;
      commit('SET_LOGGED', loginState); */


    await apiAxios.post(`${process.env.VUE_APP_API_ENDPOINT}api/uman/tokens/revoke`)
      .then(response => {
        console.log(response);
        localStorage.setItem('downloadLogs', 'false');
        if (response.data.isSuccess == true) {
          /*  let loginState = false; */
          /*    commit('SET_LOGGED', loginState); */
        }
      })
      .catch(error => {
        console.log('### ERROR: actions.revoke', error)
      })

  }
};

const getters = {

  logged: state => {
    return state.logged;
  },
  token: state => {
    return state.token;
  },
  refreshToken: state => {
    return state.refreshToken;
  },
  usersStructure: state => {
    return state.userInfo;
  },
};


export default {

  state,
  mutations,
  actions,
  getters
}
