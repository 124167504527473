import apiAxios from '../../../libs/apiAxios'
export default {
    async getContactsImportsPage({ state, commit }, data) {
        const $perPage =  parseInt(state.perPage);
        const $nrCurrentPage = parseInt(data.nrCurrentPage);
        await apiAxios.get(`api/foppacrm/contactsimports?nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            if(response.data.isSuccess == true){
                commit('SET_CONTACTS_IMPORTS', response.data)
            } else {
                commit('SET_ERROR_CONTACTS_IMPORTS', {
                    ...response.data,
                    call: "GET getCourses",
                    date: new Date()
                });
            }
          })
          .catch((error) => console.log(error));
    },

    async getContactsImportsById({ commit }, data) {
        const $id = parseInt(data.id);
        await apiAxios.get(`/api/foppacrm/contactsimports/${$id}`)
          .then(response => {
            if(response.data.isSuccess == true){
                console.log(response.data);
                commit('SET_CONTACTS_ID', response.data)
            } else {
                commit('SET_ERROR_CONTACTS_IMPORTS', {
                    ...response.data,
                    call: "GET getCourses",
                    date: new Date()
                });
            }
          })
          .catch((error) => console.log(error));
    },
}; 