export default {
  listCourses(state) {
    return state.courses;
  },

 /*  hasTypeActivity(state) {
    return state.typeActivity && state.typeActivity.length > 0;
  },*/

  typeCourseById: (state) => (id) => {
    return state.courses.find(courses => courses.id === id)
  }  
};