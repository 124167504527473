<template>
  <div class="app-skeleton">
    <app-header v-if="$route.path !== '/login'"></app-header>
    <main class="">
      <router-view />
    </main>
    <!-- <app-footer v-if="$route.path !== '/login'"></app-footer> -->
  </div>
</template>

<script>
// @ is an alias to /src
import AppHeader from "@/components/layout/AppHeader.vue";
/* import AppFooter from "@/components/layout/AppFooter.vue"; */

export default {
  name: "AppSkeleton",
  components: {
    AppHeader,
    /* AppFooter, */
  },
};
</script>
