export default {
  listActivity(state) {
    return state.typeActivity;
  },

  hasTypeActivity(state) {
    return state.typeActivity && state.typeActivity.length > 0;
  },

  typeActivityById: (state) => (id) => {
    return state.typeActivity.find(typeActivity => typeActivity.activity.id === id)
  }
};