export default {
    SET_ACTIVITY(state, data) {
        state.activity = data.payload.activities;
        state.nrActivities = data.payload.nrActivities;
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(state.activity);
        }

        return state.activity;
    },


    ADD_ACTIVITY(state, payload) {
        state.activity.push(payload);
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(payload);
            console.log(state.activity);
        }
        return state.activity;
    },

    ADD_ACTIVITY_CHILDREN(state, payload) {
        console.log(payload);
        state.activityChildren = payload;
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(payload);
            console.log(state.activityChildren);
        }
        return state.activityChildren;
    },


    EDIT_ACTIVITY(state, payload) {
        var ACTIVITY_ARR = [];
        state.activity.forEach((element, index) => {
            ACTIVITY_ARR.push({
                ...element,
                index
            })
        });
        var ACTIVITY = ACTIVITY_ARR.find(activity => activity.activity.id === payload.activity.id);
        Object.assign(state.activity[ACTIVITY.index], payload)

        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(payload);
            console.log(state.activity);
        }
        return state.activity;
    },

    DELETE_ACTIVITY(state, payload) {
        var ACTIVITY_ARR = [];
        state.activity.forEach((element, index) => {
            ACTIVITY_ARR.push({
                ...element,
                index
            })
        });
        var ACTIVITY = ACTIVITY_ARR.find(activity => activity.activity.id === payload.activity.id);
        state.activity.splice(ACTIVITY.index, 1);


        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(ACTIVITY.index);
            console.log(payload);
            console.log(state.activity);
        }
        return state.activity;
    },

    ADD_LASTACTIVITY(state, payload) {
        state.lastActivity = payload;
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(payload);
            console.log(state.lastActivity);
        }
        return state.lastActivity;
    },


    SET_ERROR_ACTIVITY(state, data) {
        state.errorCall.push(data);
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(state.errorCall);
        }
    }

};