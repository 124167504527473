export default {
    SET_TYPE_ACTIVITY(state, data) {
        state.typeActivity = data.payload.activities;
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(state.typeActivity);
        }
    },

    ADD_TYPE_ACTIVITY(state, payload) {
        state.typeActivity.push(payload);
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(payload);
            console.log(state.typeActivity);
        }
        return state.typeActivity;
    },

    EDIT_TYPE_ACTIVITY(state, payload) {
        var TYPE_ACTIVITY_ARR = [];
        state.typeActivity.forEach((element , index) => {
            TYPE_ACTIVITY_ARR.push({
                ...element,
                index
            })
        });
        var TYPE_ACTIVITY = TYPE_ACTIVITY_ARR.find(typeActivity => typeActivity.activity.id === payload.activity.id);
        Object.assign(state.typeActivity[TYPE_ACTIVITY.index], payload)
        
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(payload);
            console.log(state.typeActivity);
        }
        return state.typeActivity;
    },

    DELETE_TYPE_ACTIVITY(state, payload) {
        var TYPE_ACTIVITY_ARR = [];
        state.typeActivity.forEach((element , index) => {
            TYPE_ACTIVITY_ARR.push({
                ...element,
                index
            })
        });
        var TYPE_ACTIVITY = TYPE_ACTIVITY_ARR.find(typeActivity => typeActivity.activity.id === payload.activity.id);
        state.typeActivity.splice(TYPE_ACTIVITY.index, 1);
    
        
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(TYPE_ACTIVITY.index); 
            console.log(payload);
            console.log(state.typeActivity);
        }
        return state.typeActivity;
    },

    SET_ERROR_TYPE_ACTIVITY(state, data) {
        state.errorCall.push(data);
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(state.errorCall);
        }
    }
};