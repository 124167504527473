import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      activity: [],
      activityChildren: [],
      nrActivities: 0,
      errorCall: [],
      perPage: 50,
      lastActivity: [],
    };
  },
  mutations,
  actions,
  getters
};
