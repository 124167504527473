export default {
  activity(state) {
    return state.activity;
  },
  
  nrItem(state) {
    return state.nrActivities;
  },

  lastActivity(state) {
    return state.lastActivity;
  },

  activitiesChildren(state) {
    return state.activityChildren;
  },

  perPage(state) {
    return state.perPage;
  },

  hasActivity(state) {
    return state.activity && state.activity.length > 0;
  },

  lengthActivity(state) {
    return state.activity.length;
  },


  activityById: (state) => (id) => {
    return state.activity.find(activity => activity.activity.id === parseInt(id))
  }
};