import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      ContactsImports: [],
      ContactsById: [],
      ContactsByIdPayload: [],
      errorCall: [],
      perPage: 10,
      nrImport: 0,
    };
  },
  mutations,
  actions,
  getters
};


