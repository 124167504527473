import apiAxios from '../../../libs/apiAxios'
export default {
    async getTypeActivity({ commit }) {
        await apiAxios.get('/api/foppacrm/activitytypes')
        .then(response => {
            if(response.data.isSuccess == true){
                commit('SET_TYPE_ACTIVITY', response.data)
            } else {
                commit('SET_ERROR_TYPE_ACTIVITY', {
                    ...response.data,
                    call: "GET activitytypes",
                    date: new Date()
                });
            }
        })
        .catch((error) => console.log(error));
    }, 
    
    async putTypeActivty(context, data){
        const ActivitiesId = data.id;
  
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(data);
        }
        await apiAxios.put(`/api/foppacrm/activitytypes/${ActivitiesId}`, 
            { 
            
                "IdParent":  data.IdParent,
                "Code": data.Code,
                "Name":  data.Name,
                "Description": data.Description,
                "Sorting": parseInt(data.Sorting),
                "ImportCode": data.ImportCode
    
                
            }, 
          
        ).then(response => {
            if(process.env.VUE_APP_DEBUG=="true"){
                console.log(response.data);
            }
            if(response.data.isSuccess == true){
                context.commit('EDIT_TYPE_ACTIVITY', {
                   ...response.data.payload,
                });
            } else {
                context.commit('SET_ERROR_TYPE_ACTIVITY', {
                   ...response.data,
                   call: "PUT activitytypes",
                   date: new Date()
                });
            }
            })
            .catch((error) => console.log(error));
    },

    async postTypeActivty(context, data){
        console.log(data);
        console.log(context);
        const editData = {
                /* "IdParent":  data.IdParent,
                "Code": data.Code,
                "Name":  data.Name,
                "Description": data.Description,
                "Sorting": parseInt(data.Sorting),
                "ImportCode": data.ImportCode */

                "idActivityType": 1,
                "code": data.Code,
                "name": data.Name,
                "description":  data.Description,
                "sorting":parseInt(data.Sorting),
          };

          console.log(editData);
          console.log('POST');

        await apiAxios.post(`/api/foppacrm/activitytypes`, 
            { 
                "idActivityType": 1,
                "code": data.Code,
                "name": data.Name,
                "description":  data.Description,
                "sorting":parseInt(data.Sorting),
            }, 
        
            ).then(response => {
                console.log(response.data);
                if(response.data.isSuccess == true){
                    context.commit('ADD_TYPE_ACTIVITY', {
                       ...response.data.payload,
                    });
                } else {
                    context.commit('SET_ERROR_TYPE_ACTIVITY', {
                       ...response.data,
                       call: "POST activitytypes",
                       date: new Date()
                    });
                }
                })
            .catch((error) => console.log(error));
    },

    async deleteTypeActivty(context, data){
        console.log(data);
        const ActivitiesId = parseInt(data);
        await apiAxios.delete(`/api/foppacrm/activitytypes/${ActivitiesId}` 
           
          
        ).then(response => {
            if(process.env.VUE_APP_DEBUG=="true"){
                console.log(response.data);
            }
            if(response.data.isSuccess == true){
                context.commit('DELETE_TYPE_ACTIVITY', {
                   ...response.data.payload,
                });
            } else {
                context.commit('SET_ERROR_TYPE_ACTIVITY', {
                   ...response.data,
                   call: "PUT activitytypes",
                   date: new Date()
                });
            }
            })
            .catch((error) => console.log(error));

    },
};