export default {
    SET_CONTACT(state, data) {
        state.contact = data.payload.contacts;
        state.nrContact = data.payload.nrContacts;
        state.activitiesContacts = data.payload.activities;
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log({ message: "Data updated SET_CONTACT" });
            /*   console.log(state.activitiesContacts);
              console.log(state.contact); */
        }

        return state.contact;
    },

    SET_CURRENTPAGE(state, data) {
        state.currentPage = data;

        if (process.env.VUE_APP_DEBUG == "true") {
            console.log({ message: "Data updated SET_CURRENTPAGE" });
        }

        return state.currentPage;
    },

    ADD_CONTACT(state, payload) {
        state.contact.push(payload);
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(payload);
            /*  console.log(state.contact); */
        }
        return state.contact;
    },


    ADD_LASTCONTACT(state, payload) {

        state.lastContact = payload;
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(payload);
            console.log(state.lastContact);
        }
        return state.lastContact;
    },

    ADD_LASTCONTACTINSERT(state, payload) {

        state.lastContactInsert = payload;
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(payload);
            console.log(state.lastContactInsert);
        }
        return state.lastContactInsert;
    },

    EDIT_CONTACT(state, payload) {
        console.log(state);
        console.log(payload);

        /* var CONTACT_ARR = [];
        state.contact.forEach((element , index) => {
            CONTACT_ARR.push({
                ...element,
                index
            })
        });
        var CONTACT = CONTACT_ARR.find(contact => contact.activity.id === payload.activity.id);
        Object.assign(state.activity[CONTACT.index], payload)
        
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(payload);
            console.log(state.contact);
        }
        return state.contact; */
    },

    ADD_CONTACT_NEW(state, payload) {
        state.contact.push(payload.contacts[0]);
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(payload);
            console.log(state.contact);
        }
        return state.contact;
    },

    ADD_SINGLECONTACT(state, payload) {
        state.singleContact = payload.payload;
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(payload);
            console.log(state.singleContact);
        }
        return state.singleContact;
    },




    DELETE_CONTACT(state, payload) {
        var CONTACT_ARR = [];
        state.contact.forEach((element, index) => {
            CONTACT_ARR.push({
                ...element,
                index
            })
        });

        // inserire id contatto payload
        var CONTACT = CONTACT_ARR.find(contact => contact.id === payload.contacts[0].id);
        state.contact[CONTACT.index].status = "Archived"



        if (process.env.VUE_APP_DEBUG == "true") {
            console.log('Mutation no Payload coretto')
            console.log(payload);
        }
        return state.contact;
    },


    SET_ERROR_CONTACT(state, data) {
        state.errorCall.push(data);
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(state.errorCall);
        }
    }

};