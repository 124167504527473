export default {
    SET_COURSES(state, data) {
        state.courses = data.payload;
        if(process.env.VUE_APP_DEBUG=="true"){
            /* console.log(state.courses); */
        }
    },

    SET_ERROR_COURSES(state, data) {
        state.errorCall.push(data);
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(state.errorCall);
        }
    }
};