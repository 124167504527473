export default {
  listImports(state) {
    return state.ContactsImports;
  },

  nrItem(state) {
    return state.nrImport;
  },

  listContacts(state) {
    return state.ContactsById;
  },

  listContactsPayload(state) {
    return state.ContactsByIdPayload;
  },

 /*  hasTypeActivity(state) {
    return state.typeActivity && state.typeActivity.length > 0;
  },

  typeActivityById: (state) => (id) => {
    return state.typeActivity.find(typeActivity => typeActivity.activity.id === id)
  } */
};